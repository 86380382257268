import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './404.module.css'

const NotFoundPage = () => {
  const { formatMessage } = useIntl()

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO title="404" lang={formatMessage({ id: 'lang' })} />

      <section className={styles.section}>
        <div className="custom-container">
          <h1 className="heading text-purple">404</h1>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
